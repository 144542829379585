* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #1E96D3;
  --font-family: "Raleway", sans-serif;
  --background-color: #ffffff;
  --secondary-color: #727272;
  --color-dark: #00293E;
  --light-color: #626F76;
  --contact-main-heading-sm: 32px;
  --contact-main-heading-mob: 30px;
  --contact-main-para-sm: 19px;
  --contact-main-para-mob: 18px;
  --contact-heading: 38px;
  --contact-para: 18px;
  --contact-heading_mob: 30px;
  --contact-para_mob: 14px;
  --services_banner_heading_mob: 28px;
  --services_page_para_mob: 14px;
  --services_page_para_heading_mob: 15px;
  --services_video_height_mob: 300px;
  --services_page_tabs_mob: 15px;
  --development_process_heading_mob: 26px;
  --development_process_sub-heading_mob: 23px;
  --services_toolbox_heading_mob: 28px;
  --services_arrowbox_btn_mob: 22px;
  --services_arrow_btn_mob: 14px;
  --aboutpage_heading_mob: 30px;
  --aboutpage_para_mob: 14px;
}

h1 {
  color: var(--primary-color);
  font-family: var(--font-family);
  background-color: var(--background-color);
}

.header {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

}


.header img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

#bgVideo {
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 80%; */
  width: 100%;
  height: 100%;
  z-index: -3;
  object-fit: cover;
}

.header .over_lay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #003551;
  opacity: 32%;
  z-index: -2;
}

.navbar {
  gap: 0;
  border-radius: 5px;
  z-index: 999;
  position: fixed;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px !important;
  height: 57px;
  z-index: 99;
  background: #FFFFFFCC;
}

.logo img {
  width: 180px;
  /* height: 22px;
  position: unset; */
}

.navbar ul {
  display: flex;
  list-style: none;
  gap: 25px;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 23.48px;
  color: var(--color-dark);
}

.li span {
  color: var(--primary-color);
}

.lets {
  display: flex;
  align-items: center;
}

.lets p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: var(--color-dark);
}


.digital-heading p {
  color: var(--white-main, #FFF);
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.digital-heading .sol {
  font-family: var(--font-family);
  font-size: 70px;
  font-weight: 400;
  line-height: 82.18px;
  text-align: left;
  color: var(--background-color);
}

.digital-heading .pera {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  color: var(--background-color);
}

.digital-heading button {
  padding: 3px 11px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-h {
  display: flex;
  align-items: center;
}

.digital-heading .btn-h p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--background-color);
  margin-top: 11px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 52px !important;
  left: 0;
  /* width: 100%; */
  transition: all .35s linear;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;

}

.services-menu .service h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  color: var(--color-dark);
}




.Development {
  display: flex;
  align-items: center;
}

.Development div {

  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-right: 7px;
}

.nav_arrow {
  transition: all .35s linear;
}

.navbar .pading .devl:hover .circul {
  background: #1A1A1A;
}

.navbar .pading .devl:hover .nav_arrow {
  transform: rotate(45deg);
}

.navbar ul li .case:hover .arrow-tag {
  background: #000000;
}

.navbar ul li .case:hover .nav_arrow {
  transform: rotate(45deg);
}

.service {
  background-image: url(../src/Image/menu-bg.png);
  background-position: center;
  background-size: cover;
}



.pading {
  padding: 1.4rem 0px;
}

.service-left .lis {
  list-style: none;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.dot_col {
  color: var(--primary-color);
  font-size: 12px;
  margin-right: 4px;
}

.service-left .lis span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--secondary-color);
}

.Insight-News {
  padding: 1rem;
}

.Insight-News h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 400;
  line-height: 37.57px;
  text-align: left;
  color: var(--color-dark);
}

.insights-heading {
  padding: 1rem;
  background: #F4F8FB;
}

.insights-heading h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 400;
  color: var(--color-dark);
}


.case {
  padding: 1.6rem;
}






/*===================About-us===============*/

.img-fluidx {
  width: 100%;
  height: 100%;
}


html {
  scroll-behavior: smooth;

}

.video .over_lay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000EAA;
  opacity: 16%;
  z-index: 99;
}

.about p {
  color: var(--reg-logo, #1E96D3);
  text-align: center;
  font-family: Raleway;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;

  transition: all 2s linear;
}

.heading-side .icon-h {
  display: flex;
  align-items: baseline;
}

.icon-h div {
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  margin-right: 5px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  transition: all 0.3s linear;
  cursor: pointer;
}

.icon-h h3:hover div,
.icon-h:hover div {
  transform: rotate(45deg);
  background-color: #003551;
}

.icon-h h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28.18px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.big-img {
  margin: auto !important;
  top: 0;
}

.heading-side .p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
  color: var(--light-color);
  margin: 0;
  transition: all 2s linear;
}

.right-img {
  width: 100%;
  height: 500px;
  transition: all 1s;
}

.row .col-lg-6 .right-downc {

  position: absolute;
  box-shadow: 20px 20px 20px rgba(211, 211, 211, 0);
  top: 15%;
  left: 7%;
  padding-top: 14px;
  background-color: white;
  opacity: 0;
  transition: opacity 1s linear;
}

.row .col-lg-6 .left-downc {

  box-shadow: 20px 20px 20px rgba(211, 211, 211, 0.26);
  position: absolute;
  top: 5%;
  left: 60.5%;
  background-color: white;
  opacity: 0;
  transition: opacity 1s linear;
}

.right-downc p,
.left-downc p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: var(--light-color);
}

.scroll-box {
  width: 400px;
}

.effect {
  transition: all 1s linear;
}


.scroll_effect {
  transform: translateY(10px);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;

}

.scroll_animation {
  animation: move1 1s ease-out forwards;
}

@keyframes move1 {
  from {
    transform: translateY(100px);
    opacity: 0.5;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.display {
  opacity: 0;
}

.move_effect {
  margin-left: -75%;
  transition: all 1s linear;
}


.e_comm {
  font-weight: 900;
  line-height: 144px;
  color: #CB3F34;
  margin-left: -8rem;
  white-space: nowrap;
  position: absolute;
  right: 0%;
  text-shadow: 0px 1px black;
  top: 0;
  z-index: 111;
  transition: all 2s linear;
  opacity: 1;
}


.show-opacity {
  opacity: 1;
}

/*===================services===============*/


.services-heading {
  background: url(./Image/service-bg.png);
  padding: 1px;
}

.main_service_data {
  width: 90%;
  margin: 5rem auto;

}

.mobile-img {
  height: 105px;
  display: flex;
  align-items: center;
}

.services-heading h3 {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 600;
  line-height: 44.61px;
  text-align: left;
  color: var(--background-color);
}

.services-heading p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.48px;
  text-align: left;
  color: var(--background-color);
}

.services-card {
  padding: 10px;

}


.first-card {
  bottom: 14rem;
  height: 46%;
  overflow: hidden;
  position: absolute;
  transition: all 0.25s linear;
}

#ecommerce-card {
  height: 53%;
}

.second-card:hover #ecommerce-card {
  height: 100%;
}

.first-card p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 4.5em;
  /* Adjust based on line height */
}

.second-card:hover .first-card {
  bottom: 0;
  height: 100%;
  overflow: visible;
  background-color: var(--primary-color);

}

.second-card:hover .ser-button {
  display: none;
}

.f-c {
  padding: 3.1rem 1.1rem 2.2rem 1.1rem;
  position: relative;
}

.second-card:hover .f-c {
  padding-top: 1.1rem !important;
}

.f-c h4 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 39.01px;
  text-align: left;
  color: var(--background-color);
}

.f-c p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--background-color);
}

.f-c h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: var(--background-color);
}

.f-c span h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--background-color);
  margin-left: 5px;
}

.f-c span {
  color: var(--background-color);
  font-size: 16px;
}

.second-card {
  height: 521px;
  border: 1px solid white;
  overflow: hidden;
  background-color: transparent;
  position: relative;
}

.second-card:hover div#card\ dev_card,
.second-card:hover div#card\ design-card,
.second-card:hover #ecommerce-card,
.second-card:hover div#new-card,
.second-card:hover div#card\ seo_card,
.second-card:hover div#new-card\ new-card-1 {
  height: 100% !important;
}

.ser-button {
  display: flex;
  align-items: baseline;
  padding-left: 17px;
  position: absolute;
  bottom: 11rem;

}

.ser-button button {
  padding: 3px 11px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ser-button p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--background-color);
}

/*===================Industries===============*/


.indus {
  width: 90%;
  margin: 5rem auto;
}

.indus h3 {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 600;
  line-height: 44.61px;
  text-align: left;
  color: var(--color-dark);
}

.indus p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #7B7B7B;

}

.indus .row .col-lg-4 {
  margin-top: 10px;
}

.indus-card h3 {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 32.87px;
  text-align: left;
  color: var(--color-dark);
  margin-top: 15px;
}

.indus-card p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #727272;
}

/*===================Global===============*/

.Global {
  background-image: url(../src/Image/counter-bg.png);
  background-position: center;
  background-size: cover;
  background-color: #004F78;
  position: relative;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 20px;
}

.Global .container-fluid {
  width: 80%;
  margin: auto;
}



.Global .row {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  justify-content: space-between;
}

.Global h3 {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 600;
  line-height: 44.61px;
  text-align: left;
  color: var(--background-color);
  text-align: center;
}

.Global p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 23.48px;
  text-align: center;
  color: var(--background-color);
}

.global-card {
  padding: 20px;
}


.global-card h4 {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 400;
  line-height: 58.51px;
  color: var(--background-color);
}

.global-card h6 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 23.48px;
  color: var(--background-color);
}



/*===================news===============*/
.news .container-fluid {
  width: 90%;
}

.ad-container {
  margin: 10px 0;
}

.ad-banner {
  background-color: #f0f0f0;
  /* Light gray background */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  font-size: 14px;
  /* Font size */
  color: #333;
  /* Text color */
  text-align: center;
  /* Center the text */
}

.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /* Limits the text to 2 lines */
  line-height: 1.1em;
  /* Adjust line height as needed */
  max-height: 4em;
  /* Adjust based on line height */
}

.card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 3em;
  /* Adjust based on line height */
}

.news h2 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 46.96px;
  text-align: left;
  color: var(--color-dark);
}

.news p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #7B7B7B;
}

.news .row .col-lg-4 {
  margin-top: 10px;
}

.card-body h5 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.48px;
  text-align: left;
  color: var(--color-dark);
}

.card-body .author-details h6 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: #646C6D;
}

.card-body .dec p,
.card-body .dec span,
.card-body .dec {
  font-family: var(--font-family) !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.new {
  display: flex;
}

.new h6 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: var(--background-color);
}

.card-body p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #727272;
}

.news-card .card {
  background: #FDFDFD;
  border: none;
}

/*===================Project===============*/
.container-fluid .project {
  background-image: url(../src/Image/hello-bg.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.project-heading h4 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 300;
  line-height: 37.57px;
  text-align: center;
  color: var(--background-color);
}

.project-heading {
  padding-top: 70px;
  padding-bottom: 90px;
}

.news-card .dec {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 4.5em;

  /* Adjust based on line height */
}

.my-news .dec {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 4.5em;
  margin-bottom: 1rem;

}

/* .news-card .dec span,
.news-card .dec p{
  font-family: var(--font-family) !important;
  font-size: 16px !important;

} */
.my-news .dec span,
.my-news .dec p {
  font-family: var(--font-family) !important;
  font-size: 16px !important;

}

.project-heading h3 {
  font-family: var(--font-family);
  font-size: 52px;
  font-weight: 500;
  line-height: 61.05px;
  text-align: center;
  color: var(--background-color);
}

.project-heading p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: var(--background-color);
}

.project-heading button {
  padding: 3px 11px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;

  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-heading .btn-h {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*===================footer===============*/

.contianer-fluid .footer {
  background: #F6F6F6;
}

.footer-terms .t-c,
.footer-terms .privacy {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #727272;
  margin-right: 10px;
}

.footer-terms img {
  margin-right: 10px;
}

.footer .footer-logo {
  width: 90%;
  margin: auto;
  padding: 3rem 0 5px 0;
}

.footer-logo p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #727272;
  margin-top: 20px;
}

.footer-ul label {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.18px;
  text-align: left;
  color: var(--color-dark);
}

.footer-ul ul li {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  color: #727272;
  list-style: none;
}

.ul-li {
  border-left: 1px solid #DDE6EC;
}

.footer-ul span {
  width: 20px;
  height: 20px;
  top: 4px;
  gap: 0px;
  /* opacity: 0px; */
  color: #727272;
  margin-right: 10px;
}

.footer-ul div {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: #727272;
  margin-top: 10px;
}

.footer-ul .follow {

  margin-top: 70px;
  padding-left: 5px;
}

.follow h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.18px;
  text-align: left;
  color: var(--color-dark);
}

.footer-icon {
  display: flex;
}

.footer-icon div {
  height: 33px;
  width: 33px;
  border: 1px solid #CFCFCF;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.follow h4 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: #727272;
  margin-top: 20px;
}

.follow .email {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.email input {
  padding: 8px;
  font-family: var(--font-family);
  font-size: 16px;
  width: 62%;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  outline: none;
  border: none;
  color: #ACACAC8F;
}

.email button {
  padding: .0rem .5rem;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.78px;
  text-align: left;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.footer-terms {
  display: flex;
  justify-content: space-between;
}

.footer-terms .terms-f h5 {
  color: #727272;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-top: 10px;
}

.footer-terms .terms-f div {
  height: 15px;
  border: 1px solid #727272;
}

.terms-left {
  display: flex;
}

.terms-left span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
  text-align: left;
  color: #727272;
}

/*===================latest-case===============*/

.container-fluid .latest-case {
  width: 90%;
  margin: auto;
}

.latest-case h4 {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 600;
  line-height: 44.61px;
  text-align: left;
  color: var(--color-dark);
}

.latest-case p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #7B7B7B;
}


.laptop-img {
  width: 100%;
  height: auto;
  margin: auto;
  position: relative;
}

.Laptop {
  position: relative;
}

.latest-card {
  position: relative;
  margin-bottom: 50px;
}

.latest-card .img {
  position: relative;
}

.latest-card .img .img1 {
  position: absolute;
  height: 85%;
  width: 77.5%;
  top: 5%;
  left: 11.5%;
  overflow: hidden;

}

.latest-card .img .img1 #photohub {

  position: absolute;
  animation: scrollImage 18s linear infinite;
  height: auto;
  /* width: 90%; */
  object-fit: cover;

}


@keyframes scrollImage {
  0% {
    top: 0%;
  }

  10% {
    top: 0%;
  }

  50% {
    top: -340%;
  }

  80% {
    top: 0%;
  }

  100% {
    top: 0%;
  }


}

.latest-card .img .img1 #wholesale {

  position: absolute;
  animation: scrollImage-1 42s linear infinite;
  height: auto;
  /* width: 90%; */
  object-fit: cover;

}


@keyframes scrollImage-1 {
  0% {
    top: 0%;
  }

  10% {
    top: 0%;
  }

  50% {
    top: -865%;
  }

  80% {
    top: 0%;
  }

  100% {
    top: 0%;
  }
}


.latest-card .img .img1 #web {

  position: absolute;
  animation: scrollImage-2 36s linear infinite;
  height: auto;
  /* width: 90%; */
  object-fit: cover;

}


@keyframes scrollImage-2 {
  0% {
    top: 0%;
  }

  10% {
    top: 0%;
  }

  50% {
    top: -800%;
  }

  80% {
    top: 0%;
  }

  100% {
    top: 0%;
  }
}

.latest-card .img .img1 #property {

  position: absolute;
  animation: scrollImage-3 18s linear infinite;
  height: auto;
  /* width: 90%; */
  object-fit: cover;

}


@keyframes scrollImage-3 {
  0% {
    top: 0%;
  }

  10% {
    top: 0%;
  }

  50% {
    top: -330%;
  }

  80% {
    top: 0%;
  }

  100% {
    top: 0%;
  }
}

.Laptop img {
  width: 100%;
  height: 100%;

}

.latest-card .latest-h {
  padding-top: 10px;
}

.latest-card .latest-h h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.18px;
  text-align: left;
  color: var(--color-dark);
}

.latest-card .latest-h p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #727272;
}

/*===================Crafting===============*/

.container-fluid .crafting {
  padding: 50px 0px;
  background: #003551;
  overflow: hidden;
}

.slick-dots li button:before {
  color: white !important;
}

.crafting .row .col-lg-12 {
  display: flex;
  width: 1200% !important;
}

.crafting .text_anim {
  width: 400% !important;
  display: flex;
  animation: moveImage 15s infinite;
}

.crafting .text_anim .crafting-card {
  width: 50% !important;
}

.crafting h3 {
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 600;
  line-height: 44.61px;
  text-align: left;
  color: var(--background-color);
}

.crafting p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: var(--background-color);
}

.crafting-card span {
  font-family: var(--font-family);
  font-size: 20px;
  font-size: 96px;
  font-weight: 300;
  line-height: 112.7px;
  text-align: left;
  color: var(--primary-color);
}

.crafting-card span h4 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--background-color);
  margin-left: 5px;
}

.crafting-card .craft-ul li {
  color: var(--primary-color);
}

.crafting-card .craft-ul li h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--background-color);
}

.crafting {
  display: block;
  width: 100%;
}

.slider-x .row .col-lg-12 img {
  width: 100%;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

.crafting-card {
  position: relative;
  display: flex;
  position: relative;
  flex-direction: column;
}

.crafting-line {

  position: absolute;
  bottom: 0;
  object-fit: cover;
}

/*===================Blog===============*/

.mainframe-video {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 58px 0 62px 0;
  top: 57px;
  margin-bottom: 66px;
  background-image: url(Image/c77ca5f309053fd7372ca5dfbd41437c.gif);
  overflow: hidden;
}

.video-frame {
  position: absolute;
  width: 100%;
  height: 103%;
  filter: brightness(100%);
  background: #00486E;
  opacity: 0.8;
}

.head-tag,
.head-para {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 20px;
}

.head-para {
  margin-bottom: 32px !important;
}

.container-fluid {
  position: relative;
  z-index: 2;
}

.mainframe-video .head-tag {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.mainframe-video .head-para {
  color: var(--white, var(--white-main, #FFF));
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.navbar-1 {
  width: 100%;
  gap: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 70px;
  background: #FFFFFF;
  z-index: 3;
  position: fixed;
  top: 0;
}

.logo {
  width: 180px;
  border: 1px solid;
}

.logo img {
  width: 180px;
  /* height: 21px; */
  /* position: unset; */
}

.navbar-1 ul {
  display: flex;
  list-style: none;
  gap: 25px;
  padding: 0;
  margin: 0 70px;
}

.navbar-1 ul li {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 23.48px;
  color: var(--color-dark);
  cursor: pointer;

}

.navbar ul li {
  cursor: pointer;

}

.li span {
  color: var(--primary-color);
}

.lets {
  display: flex;
  align-items: center;
}

.header_main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.digital-heading p {
  font-family: var(--font-family);
  font-size: 46px;
  font-weight: 400;
  line-height: 56.35px;
  text-align: left;
  color: var(--background-color);
  margin: 0;
  text-transform: uppercase;
}

.digital-heading .sol {
  font-family: var(--font-family);
  font-size: 70px;
  font-weight: 400;
  line-height: 82.18px;
  text-align: left;
  color: var(--background-color);
}

.digital-heading .pera {
  font-family: var(--font-family);
  font-size: 23px;
  font-weight: 300;
  line-height: 42px;
  text-align: left;
  color: var(--background-color);
  text-transform: none;
}

.digital-heading button {
  padding: 3px 11px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-h {
  display: flex;
  align-items: center;
}

.digital-heading .btn-h p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--background-color);
  margin-top: 11px;
}

.dropdown-menu-1 {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 68px;
  left: 0;
  transition: all .35s linear;
}

.dropdown-menu-1 li {
  padding: 10px;
}

.dropdown-menu-1 li:hover {
  background-color: #F0F0F0;
}

.services-menu .service h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  color: var(--color-dark);
}

.services-menu .service p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #465760;
}

.Development {
  display: flex;
  align-items: center;
}

.Development div {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-right: 7px;
}

.service {
  background-image: url(../src/Image/menu-bg.png);
  background-position: center;
  background-size: cover;
}

.navbar ul li .devl ul {
  gap: 0px;
  margin-top: .8rem;
}

.Development h3 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left;
  color: var(--color-dark);

}

.pading {
  padding: 1.4rem 0px;
}

.service-left .lis {
  list-style: none;
  font-size: 12px;
  display: flex;
  align-items: center;

}

.dot_col {
  color: var(--primary-color);
  font-size: 12px;
  margin-right: 4px;

}

.service-left .lis span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--secondary-color);
}

.Insight-News {
  padding: 1rem;
}

.Insight-News h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 400;
  line-height: 37.57px;
  /* text-align: left; */
  color: var(--color-dark);
}

.insights-heading {
  padding: 10px;
  background: #F4F8FB;
}

.insights-heading h3 {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 400;
  color: var(--color-dark);
}

.insights-heading p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  color: #7B7B7B;
}

.case {
  padding: 1.6rem;
}

.case h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 23.48px;
  text-align: left;
  color: var(--color-dark);
}

.case p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #7B7B7B;
}

.arrow-tag {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  margin-right: 7px;
  padding: 2px;
  margin-top: 3px;
}

/*===================Blog-data===============*/
.blog-l-heading h3 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000CC;
}

.blog-l-heading img {
  height: 100%;
  width: 100%;
}

.container-fluid .blog-data {

  margin-top: 10px;
}


.bl-1 img {
  margin-right: 45px;
}

.bl-1 {
  display: flex;
  padding: 1rem;
}

.bl-1 h3 {
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
}

.bl-1 p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #00000080;

}

.bl-heading {
  margin-top: 20px;
}

.blo-tec h5 {
  display: flex;
}

.blo-tec h5 {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #00000099;
}

.blg-2 img {
  width: 100%;
  height: 100%;
}

.blg-2 {
  padding-left: 16px;

}

.blg-2 h4 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
  padding-top: 13px;
  padding-left: 13px;
}

.blog-left-card h6 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000CC;
}

.left-down-card img {
  height: 100%;
  width: 100%;
}

.left-down-card h4 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
  /* padding-top: 10px; */
}

/*===================Details===============*/

.development-services {
  padding: 14px 70px;
}

.development-services .details-parabox {
  margin: 0 0 56px 0;
}

.development-services .details-parabox .details-para {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #727272;
}

.development-services .services-imgbox {
  filter: brightness(51%);
  height: 520px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  transition: all 1.6s linear;
}

.development-services .services-imgbox video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.development-services .services-imgbox .frame {
  background: #30baffb8;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
}

.development-services .services-optionbox,
.development-services .new-services-optionbox {
  padding: 200px 0 0 0;
  transition: all 1.6s linear;
  /* border: 1px solid red; */
  height: 520px;
  overflow: hidden;
}

.services-optionbox .services-option {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  color: #727272;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #D1D1D1;
  padding: 0 0 12px 0;
  margin-bottom: 18px;
  cursor: pointer;
}

.services-optionbox .services-option:hover .option-arrow {
  transform: rotate(45deg);
  background-color: #003551;
}

.services-optionbox .services-option span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1E96D3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
  transition: all 0.3s linear;
}

.scrolled .services-imgbox {
  border-radius: 0;
}

.scrolled .services-optionbox,
.scrolled .services-optionbox {
  padding: 0;
}

/*===================Benifits===============*/

.services-benefits {
  padding: 0 70px;
  margin: 40px 0;
}

.services-benefits .heading {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #00293E;
  margin: 0 0 28px 0;
}

.services-benefits .benefits-points .points {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #727272;
  margin: 0 0 18px 0;
}

.services-benefits .benefits-points .points .bold-text {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #727272;
}

.services-benefits .benefits-points .bullet {
  font-size: 74%;
  color: #1E96D3;
  margin: 4px 2px 0 0;
}

/*===================our-tools===============*/
.our-tools {
  padding: 0 70px 80px 0;
  background-image: url(Image/development-services/image\ 35.png);
}

.our-tools .tool-text {
  padding: 54px 70px 5px 70px;
}

.our-tools .tool-text .heading {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 37.57px;
  text-align: left;
  color: #003551;
}

.our-tools .tool-text .tool-para {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #003551;
  margin: 12px 0 0 4px;
}

.our-tools .tools-iconbox .icon {
  text-align: -webkit-center;
}

.our-tools .tools-iconbox .icon .icon-img {
  /* object-fit: cover; */
  /* background-color: #E8EBEC; */
  /* box-shadow: 0px 0px 4px 0px #4B56594D; */
  display: flex;
  justify-content: center;
  /* padding: 13px 8px; */
  border-radius: 5px;
  width: 66px;
  height: 66px;
}

.our-tools .tools-iconbox .icon .icom-img-1 {
  width: 145px;
  height: 145px;
}

.our-tools .tools-iconbox .icon .icon-img-1 img {
  width: 100%;
  height: 100%;
}

.our-tools .tools-iconbox .icon .icon-img img {
  width: auto;
  height: auto;
}

.our-tools .tools-iconbox .icon .icon-name {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  color: #003551;
  width: 66px;
  text-align: center;
  margin: 8px 0 0 0;
}

.our-tools .tools-iconbox .icon .icon-name-1 {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  color: #003551;
  text-align: center;
  margin: 8px 0 0 0;
  padding: 0 5px;
}

.our-tools .toolbox_non-original_box,
.our-tools .toolbox_second_non-original_box {
  display: none;
}

/*===================devlepments-process===============*/
.row .development-process {
  background-color: #003551;
  display: flex;
  justify-content: center;
  padding: 34px 0 90px 0;
}

.development-process .heading {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 600;
  line-height: 56.35px;
  text-align: center;
  color: #FFFFFF;
  margin: 14px 0 110px 0;
}

.development-process .development-textbox .label {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 37.57px;
  text-align: left;
  color: #FFFFFF;
  margin: 0 0 16px 0;
}

.development-process .development-textbox .label-points {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  padding: 0 0 0 124px;
  color: #FFFFFF;
}

.development-process .development-textbox .label-point-dot {
  color: #1EC8D3;
  margin-right: 3px;
}

.development-process .step-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.development-process .step-box .step-num {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 28.18px;
  color: #FFFFFF;
  background-color: #1E96D3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.development-process .step-box .step {
  position: relative;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e97d38d;
  border-radius: 50%;
  z-index: 2;
}

.development-process .step-box::before,
.development-process .step-box::after,
.development-process .step-box .layer {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  animation: circleFlow 2s infinite ease-in-out;
}

.development-process .step-box::before {
  width: 72px;
  height: 72px;
  background-color: rgba(30, 151, 211, 0.4);
  animation-delay: 0s;
}

.development-process .step-box::after {
  width: 92px;
  height: 92px;
  background-color: rgba(30, 151, 211, 0.2);
  animation-delay: 0.3s;
}

.development-process .step-box .layer {
  width: 112px;
  height: 112px;
  background-color: rgba(30, 151, 211, 0.1);
  animation-delay: 0.8s;
}

@keyframes circleFlow {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.5;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.development-process .line {
  display: flex;
  justify-content: center;
  height: 100%;
}

.development-process .line .step-line {
  border: 1px dashed white;
  height: 100%;
  width: 0;
  display: flex;
}

.development-process .development-imgbox {
  width: auto;
}

.development-process .development-imgbox img {
  width: 100%;
  height: 100%;
}

/*===================story===============*/
/* .story-values {
  padding: 0 40px;
} */

.story-values .story-heading {
  font-family: var(--font-family);
  font-size: 50px;
  font-weight: 500;
  line-height: 55px;
  text-align: center;
  color: #003551;
}

.story-values .story-card {
  background-color: #F5F6F6;
  padding: 40px 0 0 0;
  margin-bottom: 30px;
  border-radius: 10px;
}

.story-card .img-box {
  display: flex;
  justify-content: center;
}

.Careers_Data {
  padding: 54px 70px;
}

.Careers_Data .heading {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #003551;
}

.Careers_Data .careers_para {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #727272;
}

.Careers_Data .career-imgbox {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 140px;
}

.Careers_Data .career-imgbox .career-imgtext {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  color: #727272;
}

.Careers_Data .available-positions {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: start;
  color: #5E5E5E;
}

.Careers_Data .head-text {
  margin-bottom: 34px;
}

.search-inputs-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-inputbox {
  display: flex;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 2px 8px 2px 16px;
  box-shadow: 0px 0px 2px 0px #1018280D;
}

.search-icon {
  color: #5E5E5E;
  margin-right: 8px;
  margin-bottom: 3px;
}

.search-inputs-container input {
  border: none;
  outline: none;
  width: 230px;
  font-size: 16px;
  color: #6C757D;
  margin: 0;
}

.search-inputs-container input::placeholder {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #5E5E5E;
}

.dropdown-container {
  display: flex;
  gap: 10px;
}

.form-select {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  background-color: #fff;
  color: hsl(208, 7%, 46%);
  width: 230px;
  font-size: 16px;
  appearance: none;
  box-shadow: 0px 0px 2px 0px #1018280D;
  margin-right: 12px;
}

.form-select::placeholder {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #667085;
}

.form-select:focus {
  outline: none;
  border-color: #80BDFF;
}

.sidebar .tab {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #494949;
  border-left: 4px solid #D9D9D9;
  border-bottom: 1.5px solid #D9D9D9;
  border-top: none;
  border-right: none;
  padding: 14px 10px;
}

.sidebar .tab:hover {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #494949;
  border-left: 5px solid #1E96D3;
  border-bottom: 1.5px solid #D9D9D9;
  border-top: none;
  border-right: none;
  padding: 14px 10px;
  box-shadow: 0px 1px 0px 0px #A7B2CC2B;
  background-color: #F2F2F2;
}

.Active {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #494949;
  border-left: 5px solid #1E96D3;
  border-bottom: 1.5px solid #D9D9D9;
  border-top: none;
  border-right: none;
  padding: 14px 10px;
  box-shadow: 0px 1px 0px 0px #A7B2CC2B;
  background-color: #F2F2F2
}

.card-bar .post-card {
  background-color: #F2F2F2;
  padding: 16px 26px;
  border-radius: 15px;
  margin: 0 0 20px 0;
}

.card-bar .post-card .post-name {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  color: #494949;
}

.card-bar .post-card .post-type {
  display: flex;
  gap: 10px;
}

.card-bar .post-card .post-type .post-btn {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
  text-align: left;
  color: #494949;
  border: 1px solid #D9D9D9;
  border-radius: 29px;
  background-color: white;
  padding: 7px 12px;
}

.active-btn {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
  text-align: left;
  background-color: #1E96D3;
  border: 1px solid #1E96D3;
  color: white;
  padding: 7px 12px;
  border-radius: 29px;
}

.card-bar .post-card .post-para {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #494949;
}

.card-bar .post-card .apply-btn {
  display: flex;
  position: relative;
  height: 30px;
  padding-bottom: 4px;
  width: fit-content;
  align-items: center;
}

.apply-btn .animation-frame {
  position: absolute;
  background-color: #1E96D3;
  top: 0;
  left: 0;
  width: 50px;
  z-index: 1;
  height: 100%;
  border-radius: 29px;
  transition: width 0.3s linear;
}

.apply-btn .sign {
  color: white;
  z-index: 2;
  font-size: 18px;
  margin-left: 15px;
  transition: margin-left 0.2s linear;
}

.apply-btn .text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1E96D3;
  z-index: 2;
  padding-top: 4px;
  margin-left: 26px;
  transition: color 0.2s linear;
}

.apply-btn:hover .animation-frame {
  width: 160px;
}

.apply-btn:hover .text {
  color: white;
  order: 1;
  margin-left: 15px;
}

.apply-btn:hover .sign {
  order: 2;
  margin-left: 10px;
}

/* /////////////////////////////////////////////////////////////////// */
.apply-btn-1 {
  display: flex;
  position: relative;
  height: 30px;
  padding-bottom: 4px;
  width: fit-content;
  align-items: center;
  margin: 28px 0 0 12px;
}

.apply-btn-1 .animation-frame {
  position: absolute;
  background-color: #1E96D3;
  top: 0;
  left: 0;
  width: 50px;
  z-index: 1;
  height: 100%;
  border-radius: 29px;
  transition: width 0.3s linear;
}

.apply-btn-1 .sign {
  color: white;
  z-index: 2;
  font-size: 18px;
  margin-left: 5px;
  transition: margin-left 0.2s linear;
}

.apply-btn-1 .text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1E96D3;
  z-index: 2;
  padding-top: 4px;
  margin-left: 26px;
  transition: color 0.2s linear;
}

.apply-btn-1:hover .animation-frame {
  width: 220px;
}

.apply-btn-1:hover .text {
  color: white;
  order: 1;
  margin-left: 15px;
}

.apply-btn-1:hover .sign {
  order: 2;
  margin-left: 10px;
}

.tell-us h3 {
  font-family: var(--font-family);
  font-size: 43px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  color: var(--color-dark);
}

.tell-us h3 span {
  color: var(--primary-color);
}

.main_missinn_about {
  background: url(./Image/our-mission-bg.png);
}

.main_missinn_about .row .col-12 h3 {
  color: var(--white, var(--white-main, #FFF));
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  margin-right: 1rem;
  line-height: 110%;
  /* 35.2px */
}

.main_missinn_about .row .col-12 p {
  color: var(--white, var(--white-main, #FFF));
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
  /* 31.48px */
}

.main_missinn_about .row .col-12 .about_video {
  position: relative;
  width: 100%;
  height: 100%;
}

.main_missinn_about .row .col-12 .about_video video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  /* background: red; */
}

.main_missinn_about .row .col-12 .about_video .over_lay {
  background: #00293E;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  opacity: 40%;

}

.story-card .card-texts {
  padding: 7px 0 0 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.story-card .card-texts .heading {
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
  color: #003551;
}

.story-card .card-texts .card-body {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 400;
  line-height: 34.63px;
  color: #797979;
  padding: 0 30px 0 0;
  margin: 5px 0;
  max-height: 140px;
  overflow: scroll;
}

.story-card .card-texts .card-body::-webkit-scrollbar {
  display: none;
}



/*===================our-details===============*/
.aboutus-details .head_details {
  position: relative;
  padding: 30px 0 30px 30px;
  overflow: hidden;
}

.aboutus-details .head_details::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: url(./Image/k_logo.png) center / cover no-repeat;
  animation: slideIn 1.8s ease forwards;
  animation-delay: 1.5s;
}

@keyframes slideIn {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}


/* .head-details .k_logo {
  position: absolute;
} */

.aboutus-details .head_details .heading {
  font-family: var(--font-family);
  font-size: 46px;
  font-weight: 400;
  line-height: 56.35px;
  /* text-align: left; */
  color: #003551;
  margin-bottom: 12px;
  /* position: absolute; */
  top: 70px;
  left: 45px;
}

.aboutus-details .head_details .sub-heading {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  /* text-align: left; */
  color: #003551;
  /* position: absolute; */
  top: 250px;
  left: 45px;
}

.aboutus-details .details-para {
  color: var(--light-para, #727272);
  text-align: justify;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 133.333% */
}

.new_head-details,
.background_image .second_img {
  display: none;
}



.aboutus-details .new_head-details .heading {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 400;
  line-height: 56.35px;
  text-align: left;
  color: #003551;
  margin-bottom: 12px;
}

.aboutus-details .new_head-details .sub-heading {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  text-align: left;
  color: #003551;
}

/*===================data===============*/
.data {
  top: 20px;
  margin-bottom: 10px;
  position: relative;
  padding: 20px;
}

.data-card h4 {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 42.26px;
  text-align: left;
  color: var(--background-color);
  margin-bottom: 18px;
}

.data-card {
  padding-top: 49px;
  padding-left: 20px;
  padding-bottom: 25px;
  background: #003551;
  height: 100%;
}

.how {
  margin-top: 46px;
}

.how h3 {
  text-align: center;
  padding-top: 20px;
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 24px;
  color: var(--color-dark);
}

.m-map {
  display: flex;
  /* justify-content: space-between; */
  padding: 15px;
}

.how p {
  text-align: center;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #727272;
}

.map-heading p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
}

.map-heading span {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  color: var(--secondary-color);
}

.data-card h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--background-color);
}

.redious-card {
  display: flex;
  flex-wrap: wrap;
}

.redious-card-btn {
  border: 1px solid var(--background-color);
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 15.26px;
  color: var(--background-color);
  background: transparent;
  margin-right: 15px;
  margin-top: 10px;
}

.selected_option-btn {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 15.26px;
  color: var(--background-color);
  margin-right: 15px;
  margin-top: 10px;
}

.data-input {
  display: flex;
  margin-top: 35px;
  border: 1px solid;
}

.data-input .inp_login input {
  border: 1px solid;
  width: 230px;
}

.white-space {
  flex-wrap: nowrap;
}

.contect_input label {
  color: var(--white, var(--white-main, #FFF));
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.contect_input input {
  width: 100%;
  padding: .5rem;
  margin-top: .5rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
}

.contect_input select {
  width: 100%;
  padding: .5rem;
  margin-top: .5rem;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.contect_input .contact_contry .img_container {
  width: 136px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px 0 0 5px;
  background: #F5F5F5;
  padding: 1.3rem .8rem 1.3rem;
}

.contect_input .contact_contry .img_container span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #545468;
  margin: 0 6px;
}

.contect_input select option:disabled {
  color: #A8A8A8;
}

.contect_input textarea {
  width: 100%;
  padding: .5rem;
  margin-top: .5rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 160px;
  outline: none;
}

.contect_input input::-webkit-inner-spin-button,
.contect_input input::-webkit-outer-spin-button {
  display: none;
}

.contect_input select {
  color: #A8A8A8;
}


.data-input input {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
  outline: none;
}

.data-input h5 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--background-color);
}

.inp_login .contact_contry {
  display: flex;
  background: #F5F5F5;


}

.inp_login .contact_contry .img_container {
  width: 100px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px 0 0 5px;
  background: #F5F5F5;
}

.inp_login .contact_contry .img_container span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #545468;
}

.inp_login input {
  height: 38px;
  margin-top: 4.8px;
  width: 150px;
}

.img_container {
  height: 35px;
  margin-top: 8px;
}

.input-text {
  margin-top: 20px;
}

.input-text h3 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--background-color);
}

.input-text .inp-textar {
  width: 97%;
  /* height: 0; */
  padding: 10px;
  border-radius: 6px;
}

.inp-textar textarea {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.apply-btn {
  display: flex;
  position: relative;
  height: 30px;
  padding-bottom: 4px;
  width: fit-content;
  align-items: center;
}

.apply-btn .animation-frame {
  position: absolute;
  background-color: #1E96D3;
  top: 0;
  left: 0;
  width: 50px;
  z-index: 1;
  height: 100%;
  border-radius: 29px;
  transition: width 0.3s linear;
}

.apply-btn .sign {
  color: white;
  z-index: 2;
  font-size: 18px;
  margin-left: 15px;
  transition: margin-left 0.2s linear;
}

.apply-btn .text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1E96D3;
  z-index: 2;
  padding-top: 4px;
  margin-left: 26px;
  transition: color 0.2s linear;
}

.apply-btn:hover .animation-frame {
  width: 140px;
}

.apply-btn:hover .animation-frame-1 {
  width: 150px !important;
}

.apply-btn:hover .text {
  color: white;
  order: 1;
  margin-left: 15px;
}

.apply-btn:hover .sign {
  order: 2;
  margin-left: 10px;
}


.tell-us h3 span {
  color: var(--primary-color);
}

.tell-us {
  padding: 206px 120px 70px 40px;
  height: 100%;
  background: #F4F5F6;
}

.tell-us p {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--color-dark);
}

/*===================blogsingle=======================*/
.blog-singal {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
}



.blog-singal .blog-span {
  display: flex;
  margin-top: 30px;
}

.blog-singal .blog-span span {
  display: flex;
  margin-right: 14px;
  align-items: center;
}

.blog-span h4 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #7D7D7D;
  margin-left: 7px;
  margin-top: 10px;
}

.singal-card h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 37.92px;
  text-align: left;
  color: #313839;
  margin-top: 20px;
}

.singal-card p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #727272;
  margin-top: 20px;
}

.blog-li {
  margin-top: 23px;
}

.singal-card .blog-li ul li {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30.1px;
  color: #727272;
  margin: 10px;
}

.testimonial {
  width: 100%;
  margin-top: 30px;
}

.testimonial .test {
  width: 100%;
  padding: 10px;
  height: 412px;
  /* border-radius: 30px; */
  background: #1C9093;
}

.test .test-icon {
  width: 68px;
  height: 68px;
  margin: auto;
  margin-top: 70px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.test p {
  text-align: center;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30.1px;
  text-align: center;
  color: var(--background-color);
}

.singal-div {
  display: flex;
  margin-top: 25px;
  /* justify-content: space-between; */
}

.singal-div h4 {
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: 500;
  line-height: 36.57px;
  text-align: left;
  color: #303030;
}

.singal-icon {
  display: flex;
  padding-left: 12px;
}

.singal-icon div {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background: #1C9093;
  color: white;
}

.singal-input {
  display: flex;
  margin-top: 30px;
}

.singal-input .name input {
  height: 40px;
  width: 360px;
  border: 1px solid #D0D5DD;
  margin-right: 30px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family);
  padding: 13px;
  outline: none;
}

.singal-input .name h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-web {
  margin-top: 30px;
}

.singal-web h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-web input {
  height: 40px;
  width: 750px;
  border: 1px solid #D0D5DD;
  margin-right: 30px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family);
  padding: 13px;
  outline: none;
}

.describe-input {
  margin-top: 30px;
}

.describe-input h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.describe-input input {
  width: 750px;
  height: 122px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  outline: none;
}

.singal-card .checkbox {
  margin-top: 20px;
  display: flex;
}

.checkbox input {
  height: 20px;
  width: 20px;
  outline: none;
  margin-right: 10px;
}

.checkbox h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-button {
  margin-top: 30px;
}

.singal-button button {
  padding: .3rem 3.3rem;
  background: #1C9093;
  color: white;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: none;
  border-radius: 4px;
  outline: none;
}

.singal-side-card {
  width: 100%;
}

.singal-side-card .serch-card {
  width: 100%;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
}

.serch-card input {
  border: none;
  padding: 10px;
  width: 100%;
  background-color: #EAEAEA;
  outline: none;
  color: white;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;

}

.serch-card input::placeholder {
  color: #4E4E4E;
}

.Recent-post {
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
  padding-left: 10px;

}

.recent-img {
  display: flex;

}

.recent-img .r-img {
  width: 100px !important;
  height: 100px !important;
}

.recent-img img {
  /* margin-right: 20px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Recent-post h3 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 53.28px;
  color: #303030;
}

.recent-heading {
  width: max-content;
  display: flex;
  padding: 0 .5rem;
  flex-direction: column;
  width: 75%;
}

.recent-img .recent-heading h5 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.7px;
  text-align: left;
  color: #313839;
}

.blog-categories {
  width: 100%;
  margin-top: 25px;
  padding: 1.4rem;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
  border-radius: 10px;
}

.blog-categories h3 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 53.28px;
  text-align: left;
  color: #313839;
}

.blog-categories div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px dotted;
  padding: 6px;
}

.blog-categories div h5 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #313839;
}

.blog-categories div h6 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
  color: #313839;
  padding-top: 30px;
}

.blog-follow {
  border-radius: 10px;
  margin-top: 25px;
  padding: 1.2rem;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);

}

.blog-follow .follow-icon {
  display: flex;
}

.follow-icon div {
  height: 32px;
  width: 33px;
  border-radius: 100px;
  margin-right: 18px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  background-color: #1C9093;
  color: white;
}

.view-button {
  margin-top: 25px;
}

.view-button button {
  padding: .1rem 9rem;
  background: #1C9093;
  font-family: var(--font-family);
  font-size: 14px;
  border: none;
  font-weight: 500;
  /* line-height: 24px; */
  text-align: left;
  color: white;
  border-radius: 4px;
}

.news-button {
  padding-top: 20px;
}

.news-button button {
  padding: 7px 125px;
  background: #1C9093;
  color: #FFFFFF;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

/*===================Latest-news===============*/

.latest-news {
  width: 100%;
  margin-top: 40px;
}

.latest-heading h3 {
  font-family: var(--font-family);
  font-size: 33.21px;
  font-weight: 400;
  line-height: 33.21px;
  text-align: left;
  margin-right: 16px;
}

.latest-heading {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 24px;
}

.latest-heading div {
  width: 400px;
  border: 1px solid #1A1A1A;
}

.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-h .readmore_hr {
  color: #727272;
  width: 84%;
  margin: 10px auto;
}

.latest-h .read-more_btn {
  font-family: var(--font-family);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 12px;
  color: #727272;
  text-decoration: none;
}


.box h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: #FDFDFD;
}

.box {
  padding: 0 12px;
}

.late-heading h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: #313839;
  margin-top: 10px;
}

.late-heading h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 3em;
  /* Adjust based on line height */
}

.late-heading {
  padding: 5px 20px;
  /* box-shadow: 0 0 20px lightgrey; */
  border-radius: 3px;
}

.late-heading p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1E96D3;
}

.late-heading p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /* Limits the text to 2 lines */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 4.5em;
  /* Adjust based on line height */
}

.late-heading h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: #646C6D;
}

.my-news {
  padding: 25px 10px 8px 10px;
}

.my-news .dec p,
.my-news .dec,
.my-news .dec span {
  color: var(--primary-color) !important;
}

.latest-news .row .col-lg-3 {
  border: 1px solid;
}

.mandorty {
  padding: 15px 17px 5px 17px;
}

.side-late-heading h3 {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #1A1A1A;
}

.side-late-heading h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: var(--primary-color);
}

.side-late-heading .d-flex h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #494949;
}

.m-tags {
  padding: 0 0 0 10px;

}

.side-latest-card {
  padding: 5px;
  /* box-shadow: 0 0 20px lightgrey; */
  border-radius: 10px;
}



.swiper-slide .swiper-slide-active {
  width: 915px !important;
}




/*===================media-queris===============*/
.nav_btn {
  background: none;
  border: none;
  display: none;
}

.side_bar {
  width: 300px;
  /* border: 1px solid; */
  position: fixed;
  background: white;
  height: 100vh;
  z-index: 9999;
  transition: all 0.35s linear;
}

.side_bar svg {
  margin: 1rem;
}

.side_bar .ul .li {
  text-transform: uppercase;
  list-style: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: .5rem;
  padding: .5rem;

}

.side_bar .ul .li .arrow {

  transition: all.35s linear;

}

.side_bar .ul .li ul li {
  text-transform: uppercase;
  list-style: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;


}

.side_bar .ul .li ul li .dot {
  color: var(--primary-color);


}

.about {
  width: 90%;
  margin: auto;
  padding: 1rem;
}

.about_mobile {
  display: none;
}

/* /////////////////////////////////////////// pagination //////////////// */


.pagination-btn .page-link.active {
  background-color: #646C6D;
  color: white;
}

.pagination-btn .page-link {
  border: 1px solid #646C6D;
  color: #646C6D;
  margin: 0 8px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}

.pagination-btn .page-link:hover {
  background-color: #646C6D;
  color: white;
}

.page-item:last-child .page-link:hover,
.page-item:first-child .page-link:hover {
  background-color: #646C6D;
  color: white;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
  color: #646C6D;
  border: 1px solid #BCBCBC;
  padding-right: 12px;
  padding-left: 12px;
}

.page-item:first-child .page-link {
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid #BCBCBC;
  color: #BCBCBC;
  background-color: transparent;
}

.pagination .page-item.disabled .page-link {
  color: #BCBCBC;
  cursor: not-allowed;
}


/* /////////////////////////// [ pic-section ] /////////////// */

.pic-section {
  background-color: #1E96D3;
  padding: 40px 70px 10px 70px
}

.pic-section .section-heading {
  font-family: var(--font-family);
  font-size: 54px;
  font-weight: 400;
  line-height: 63.4px;
  text-align: center;
  color: #FFFFFF;
}

/* //////////////////////////////////////////////////// [ media query ] /////////////////////////////////////////////////////////////////// */


@media (max-width:1200px) {
  .tell-us {
    padding-right: 70px;
  }

  .new-tool-text {
    padding-right: 30px !important;
  }
}

@media (max-width: 1100px) {
  .navbar .nav_logo svg {
    width: 150px;
  }

  .navbar .li ul li {
    font-size: 14px;
  }

  .navbar .lets {
    font-size: 18px;
  }

  .about p {
    font-size: 28px;

  }

}


@media (max-width: 990px) {
  .crafting h3 {
    font-size: 38px;
    line-height: 44.61px;
  }

  .crafting p {
    font-size: 20px;
    line-height: 44.61px;


  }

  .about p {
    font-size: 25px;
    line-height: 25px;
  }

  /* .development-process .development-textbox {
    padding-left: 48px;
  } */

  .navbar .nav_logo svg {
    width: 90px;
  }

  .navbar ul {
    gap: 18px;
  }

  .navbar .li ul li {
    font-size: 12px;
  }

  .navbar .lets p {
    font-size: 14px;
  }

  .Global .container-fluid .global-card .global-img img {

    width: 80px;
  }

  .Global .container-fluid .global-card h4 {
    font-size: 35px;
    line-height: 25px;
  }

  .Global .container-fluid .global-card h6 {
    line-height: 25px;
    font-size: 15px;
  }

  .about_dec {
    display: none;
  }

  .about_mobile {
    display: block;
  }

  .hide_box,
  .contact-card {
    display: none;
  }

  .show_box {
    display: block !important;
    margin-bottom: 50px;
  }

  .tell-us h3,
  .data-card h4 {
    font-size: var(--contact-heading);
  }

  .tell-us p {
    font-size: var(--contact-para);
  }

  .about_mobile .p {
    margin-bottom: 30px;
  }

  .development-process .development-imgbox img {
    width: auto;
    height: auto;
  }

  .tool-text .heading br {
    display: none;
  }

  .head_details {
    display: none !important;
  }

  .new_head-details {
    display: block !important;
    margin: 40px 0 30px 0;
  }

  .background_image {
    position: relative;
  }

  .background_image .second_img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }

  .background_image .second_img img {
    width: 100%;
    height: 100%;
  }

  .Careers_Data .heading {
    font-size: 40px !important;
  }

  .Careers_Data .careers_para {
    font-size: 16px !important;
  }

  .new_head-details .heading {
    font-size: 38px !important;
  }

  .new_head-details .sub-heading {
    font-size: 18px !important;
  }

  .aboutus-details .details-para {
    font-size: 16px !important;
  }

  .pic-section {
    padding: 40px 0 30px 0px !important;
  }

  .pic-section .section-heading {
    font-size: 40px !important;
    line-height: 50px !important;
    padding: 0 30px;
  }

  .digital-heading .sol {
    font-size: 53px !important;
  }

  .digital-heading .first-para {
    font-size: 39px !important;
  }

  .digital-heading .pera {
    line-height: 32px;
  }
}

@media (max-width: 768px) {
  .development-process .development-imgbox {
    margin: 0 0 30px 0;
  }

  .about p {
    font-size: 20px;
    line-height: 20px;
  }

  .navbar .li {
    display: none;
  }

  .navbar .lets {
    display: flex;
    align-items: center;
  }

  .navbar .lets p {
    margin: 0;
    margin-right: 1rem;
    padding: 0;
    height: 100%;

  }

  .nav_btn {
    display: block;
    padding: 0;
    margin: 0;
    height: 100%;

  }

  .Global .container-fluid .global-card .global-img img {
    width: 50px;
    height: 50px;
  }

  .Global .container-fluid .global-card h4 {
    font-size: 25px;
    line-height: 25px;
  }

  .Global .container-fluid .global-card h6 {
    line-height: 25px;
    font-size: 12px;
  }

  .Global .container-fluid h3 {
    font-size: 25px;
    line-height: 20px;
  }

  .Global .container-fluid p {
    font-size: 13px;
    line-height: 20px;
  }

  .project-heading h4 {
    font-size: 20px;
    line-height: 20px;
  }

  .project-heading h3 {
    /* display: none; */
    font-size: 25px;
    line-height: 25px;
  }

  .project-heading h3 br {
    display: none;
    font-size: 20px;
  }

  .project-heading p {
    font-size: 15px;
    font-weight: 500;
    margin: 0 2rem;
  }

  .project-heading p br {
    display: none;
  }

  .latest-case h4 {
    font-size: 25px;
    line-height: 25px;
  }

  .latest-case p {
    font-size: 15px;
    line-height: 15px;
  }

  .about_mobile h3 {
    font-size: 18px;
  }

  .about_mobile .p {
    font-size: 12px;
    line-height: 15px;
  }

  .crafting h3 {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
  }

  .crafting p {
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
  }

  .location_col {
    padding-left: 0 !important;
  }

  .development-process .new-seo-heading {
    padding: 25px 0 60px 0 !important;
  }

  .new_head-details .heading {
    font-size: 36px !important;
  }

  .new_head-details .sub-heading {
    font-size: 17px !important;
  }

  .story-values .story-heading {
    font-size: 42px !important;
  }

  .career-imgbox img {
    width: auto !important;
    height: auto;
  }

  .data-card {
    padding: 49px 20px 25px 20px;
  }
}

@media (max-width: 560px) {
  .development-process .heading {
    padding: 50px 0;
  }

  .about p {
    font-size: 15px;
    line-height: 15px;
  }

  .crafting h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  .crafting p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  .development-process .pad-box {
    padding: 0 20px;
  }

  .indus h3 {
    font-size: 25px;
  }

  .indus p {
    font-size: 15px;
    line-height: 25px;
  }

  .Global .container-fluid .d-flex {
    flex-wrap: wrap;
  }

  .Global .container-fluid .global-card {
    width: 50%;
    text-align: center;
  }

  .Global .container-fluid .global-card .global-img img {
    width: 30px;
    height: 30px;
  }

  .Global .container-fluid .global-card h4 {
    font-size: 15px;
    line-height: 15px;
  }

  .Global .container-fluid .global-card h6 {
    line-height: 10px;
    font-size: 8px;
  }

  .news .container-fluid h2 {
    margin-top: 20px;
    font-size: 25px;
    line-height: 25px;
  }

  .news .container-fluid p {
    font-size: 15px;
    line-height: 15px;
  }
}


@media (max-width: 990px) {
  /* .development-process .development-textbox {
    padding-left: 48px;
  } */
}

@media (max-width: 768px) {
  .development-process .development-imgbox {
    margin: 0 0 30px 0;
  }

}

@media (max-width: 990px) {
  .development-services {
    padding: 14px 40px 0 40px;
  }

  .services-benefits {
    padding: 0 40px 0 40px;
  }

  /* .development-process .development-textbox {
    padding-left: 48px;
  } */

  .development-services .services-optionbox {
    height: 300px;
    overflow: hidden;
  }

  .development-services .new-services-optionbox {
    height: 410px;
    overflow: hidden;
  }

  .services-optionbox-1 {
    height: 540px;
    overflow: hidden;
  }

  .development-services .services-optionbox-2 {
    height: 348px;
    overflow: hidden;
  }

  .mainframe-video .head-tag {
    line-height: 50px;
  }

  .development-imgbox {
    display: flex;
    justify-content: center;
  }

  /* .development-imgbox img {
    max-width: 60%;
  } */

  .our-tools {
    padding: 0;
  }

  .follow .email input {
    width: 80%;
  }

  .tell-us {
    padding-top: 70px;
  }

}


@media (max-width: 768px) {
  .development-process .heading {
    padding: 0px 30px 50px 30px;
    font-size: 37px;
  }

  .services-heading h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .services-heading p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
  }

  .development-process .development-imgbox {
    margin: 0 0 30px 0;
  }

  .Careers_Data .heading {
    line-height: 48px;
  }

  .search-inputs-container {
    flex-direction: column;
    gap: 15px;
  }

  .form-select {
    width: 100%;
  }

  /* .development-imgbox img {
    max-width: 80%;
  } */

  .development-process .img-col {
    padding-bottom: 40px !important;
  }

  .development-process .development-imgbox {
    display: flex;
    justify-content: center;
  }

  .digital-heading .pera {
    font-size: 15px;
    line-height: 26px;
  }


  .digital-heading .sol {
    font-size: 50px;
  }


  .digital-heading .first-para {
    font-size: 26px !important;
  }

  .apply-btn .text {
    font-family: 14px;
  }

  .tell-us {
    padding-top: 70px;
  }

  .redious-card-btn,
  .selected_option-btn {
    margin-bottom: 8px;
  }

  .how h3 {
    font-size: var(--contact-main-heading-sm);
  }

  .how p {
    font-size: var(--contact-main-para-sm);
  }


}

@media (max-width: 560px) {
  .navbar {
    width: 90%;
  }



  .development-services {
    padding: 14px 30px 0 30px;
  }

  .services-benefits {
    padding: 0 30px 0 30px;
  }

  .development-services .services-optionbox {
    height: 300px;
    overflow: hidden;
  }

  .development-services .new-services-optionbox {
    height: 420px !important;
    overflow: hidden;
  }

  /* 
  .development-services .services-optionbox-1 {
    height: 550px;
    overflow: hidden;
    margin-bottom: 30px;
  } */

  .development-services .servicesbox-responsive {
    height: 270px;
    overflow: hidden;
  }

  .development-process .heading {
    margin-bottom: 0;
  }

  .development-process .img-col {
    padding-bottom: 40px !important;
  }

  .development-process .development-imgbox {
    display: flex;
    justify-content: center;
  }

  /* .development-imgbox img {
    max-width: 70%;
  } */
  .tell-us {
    padding-top: 70px;
  }
}

@media (max-width: 500px) {
  .tell-us {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 70px;
  }

  .tell-us h3,
  .data-card h4 {
    font-size: var(--contact-heading_mob);
  }

  .tell-us p {
    font-size: var(--contact-para_mob);
  }

  .data-card {
    padding-right: 20px;
  }

  .how h3 {
    font-size: var(--contact-main-heading-mob);
  }

  .how p,
  .map-heading p {
    font-size: var(--contact-main-para-mob);
  }

  .mainframe-video .col-10 {
    padding: 0 !important;
  }

  .mainframe-video .head-para {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .latest-case h4,
  .latest-case p,
  .latest-h h3,
  .latest-h p,
  .indus-card h3,
  .indus-card p,
  .indus h3,
  .indus p,
  .main_service_data h3 {
    text-align: center !important;
  }

  .news .container-fluid p {
    line-height: 20px;
  }

  .about p {
    font-size: 16px !important;
    line-height: 21px !important;
  }

  .about_mobile h3 {
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }

  .heading-side .icon-h {
    margin-bottom: 10px;
  }

  .development-process .development-textbox .label {
    font-size: var(--development_process_sub-heading_mob);
    text-align: center;
  }

  .development-process .heading {
    font-size: var(--development_process_heading_mob);
  }

  .mainframe-video .head-tag {
    font-size: var(--services_banner_heading_mob);
    line-height: 40px;
  }

  .development-services .details-parabox .details-para {
    font-size: var(--services_page_para_mob);
  }

  .services-optionbox .services-option {
    font-size: var(--services_page_tabs_mob);
    font-weight: 500;
  }

  .development-services .services-imgbox {
    height: var(--services_video_height_mob);
  }

  .services-benefits {
    margin: 0;
  }

  .services-benefits .heading {
    text-align: center;
  }

  .row .development-process {
    padding: 0 0 35px 0;
  }

  .services-benefits .benefits-points .points,
  .our-tools .tool-text .tool-para {
    font-size: var(--services_page_para_mob);
  }

  .services-benefits .benefits-points .points .bold-text {
    font-size: var(--services_page_para_heading_mob);
  }

  .development-process .heading {
    padding: 25px 0 50px 0 !important;
  }

  .tool-text .heading {
    font-size: var(--services_toolbox_heading_mob) !important;
  }

  .our-tools .tool-text {
    padding: 54px 20px 5px 20px;
  }

  .development-process .development-imgbox img {
    width: 80%;
    height: auto;
  }

  .navbar {
    margin-top: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
  }

  #homepage_about_video {
    height: 300px !important;
  }

  .icon-h div {
    padding: 3px 3px !important;
  }

  .icon-h svg {
    font-size: 14px !important;
  }

  #ecommerce-card {
    height: 53% !important;
  }

  #card,
  #new-card {
    height: 47%;
  }

  .footer-ul ul label,
  .footer-ul ul a,
  .footer-ul ul div {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .phone-icon {
    margin-right: 5px !important;
  }

  .follow h3,
  .follow h4,
  .follow .footer-icon {
    display: flex;
    justify-content: center;
  }

  .terms-left h4 {
    font-size: 11px !important;
  }

  .footer-ul li,
  .footer-ul div,
  .follow h4 {
    font-size: 12px !important;
  }

  .pin_icon {
    font-size: 16px !important;
    margin-right: 2px !important;
  }

  .contact_icon {
    height: 14px !important;
    margin-right: 2px !important;
  }

  .mail_icon {
    height: 12px !important;
    margin-right: 2px !important;
  }

  .Careers_Data {
    padding: 54px 30px !important;
  }

  .Careers_Data .heading {
    font-size: 27px !important;
    line-height: 35px !important;
  }

  .Careers_Data .careers_para {
    font-size: 12px !important;
  }

  .services-optionbox .services-option span {
    width: var(--services_arrowbox_btn_mob) !important;
    height: var(--services_arrowbox_btn_mob) !important;
  }

  .services-optionbox .services-option span svg {
    font-size: var(--services_arrow_btn_mob) !important;
  }

  .story-values .story-heading {
    font-size: var(--aboutpage_heading_mob) !important;
    line-height: 44px !important;
  }

  .aboutus-details .details-para,
  .story-card .card-texts .card-body {
    font-size: var(--aboutpage_para_mob) !important;
    text-align: left;
  }

  /* .story-card .card-texts .card-body{
    padding-right: 0 !important;
  } */
  .story-card .card-texts .heading {
    font-size: 26px !important;
  }

  .main_missinn_about .row .col-12 p {
    font-size: 14px !important;
  }

  .aboutus-details .head_details .heading {
    left: 19px !important;
  }

  .aboutus-details .head_details .sub-heading {
    left: 10px !important;
  }

  .icon-h h3 {
    line-height: normal !important;
  }

  .about {
    width: 100% !important;
    padding: 0 !important;
  }

  .second-card:hover .first-card,
  .second-card:hover #ecommerce-card {
    height: 100% !important;
  }

  .career-imgbox img {
    width: 100% !important;
    height: 100%;
  }

  .Careers_Data {
    padding: 54px 20px !important;
  }

  .head_details {
    display: block !important;
  }

  .new_head-details,
  .second_img {
    display: none !important;
  }

  .head_details .heading br {
    display: none;
  }

  .head_details .heading {
    font-size: 34px !important;
    font-weight: 500 !important;
    top: 75px !important;
    line-height: 40px !important;
  }

  .head_details .sub-heading {
    font-size: 18px !important;
    top: 170px !important;
  }

  .k_log0 {
    width: 100%;
    height: 330px !important;
  }

  .k_log0 img {
    width: 100%;
    height: 100% !important;
  }

  .aboutus-details .head_details {
    padding: 15px 20px !important;
  }

  .new_card_body {
    text-align: center !important;
    padding: 0 10px !important;
  }

  .pic-section .section-heading {
    font-size: 27px !important;
    line-height: 35px !important;
    font-weight: 500 !important;
  }

  .digital-heading .sol {
    line-height: 54px !important;
  }

  .digital-heading .first-para {
    line-height: 30px !important;
    font-size: 26px !important;
  }

  .development-services .new-services-optionbox {
    height: 360px !important;
    overflow: hidden;

  }

  .services-optionbox .services-option {
    line-height: 23px !important;
  }

  .development-services .services-optionbox {
    height: 270px !important;
  }
}

@media (max-width:370px) {
  .head_details .heading {
    top: 38px !important;
  }

  .head_details .sub-heading {
    font-size: 18px !important;
    top: 174px !important;
  }
}

@media (max-width:350px) {
  #new-card {
    height: 53%;
  }

  .head_details .heading {
    font-size: 27px !important;
    line-height: 35px !important;
    top: 50px !important;
  }

  .head_details .sub-heading {
    top: 130px !important;
  }

  .k_log0 {
    height: 275px !important;
  }

  .aboutus-details .head_details {
    padding: 0 0 15px 0 !important;
  }

}


@media (max-width:600px) {

  .our-tools .toolbox_original_box,
  .our-tools .toolbox_second_non-original_box {
    display: none;
  }

  .our-tools .toolbox_non-original_box {
    display: block;
  }
}

@media (max-width: 576px) {
  .story-card .card-body {
    padding: 0 10px !important;
  }
}

@media (max-width: 450px) {
  .navbar {
    width: 100%;
    margin-top: 0px;
  }

  .development-process .heading {
    padding: 0 20px;
    line-height: 46px;
    /* margin-bottom: 80px; */
  }

  .development-services .services-optionbox {
    height: 340px;
    overflow: hidden;
  }



  .development-services .servicesbox-responsive {
    height: 280px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .development-services .services-optionbox-1 {
    height: 760px;
    overflow: hidden;
  }

  .digital-heading p {
    font-size: 18px;
    line-height: 20px;
  }

  .digital-heading .sol {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 12px;
    margin-top: 6px;
  }

  .digital-heading .pera {
    font-size: 12px;
    line-height: 20px;
  }

  .apply-btn .text {
    font-family: 14px;
  }

}

@media (max-width: 400px) {

  .our-tools .toolbox_original_box,
  .our-tools .toolbox_non-original_box {
    display: none;
  }

  .our-tools .toolbox_second_non-original_box {
    display: block;
  }
}



/* ///////////////////////////////////////////////// [ media query for services cards ( homepage ) ] //////////////////////////////// */
@media (max-width:10000px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card,
  div#new-card,
  div#new-card\ new-card-1,
  #ecommerce-card {
    height: 39% !important;
  }
}


@media (max-width:2000px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card,
  div#new-card,
  div#new-card\ new-card-1 {
    height: 39% !important;
  }

  #ecommerce-card {
    height: 46% !important;
  }
}

@media (max-width:1939px) {
  div#new-card\ new-card-1 {
    height: 46% !important;
  }
}

@media (max-width:1600px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card,
  div#new-card {
    height: 39% !important;
  }

  #ecommerce-card,
  div#new-card\ new-card-1 {
    height: 46% !important;
  }
}

@media (max-width:1494px) {
  div#new-card {
    height: 46% !important;
  }
}

@media (max-width:1403px) {
  div#card\ design-card {
    height: 39% !important;
  }

  div#card\ seo_card {
    height: 46% !important;
  }

}

@media (max-width:1365px) {

  div#card\ seo_card,
  div#card\ design-card {
    height: 46% !important;
  }

}

@media (max-width:1362px) {

  div#card\ seo_card,
  div#card\ design-card {
    height: 46% !important;
  }

}

@media (max-width:1356px) {
  div#card\ dev_card {
    height: 46% !important;
  }
}

@media (max-width:1340px) {
  #ecommerce-card {
    height: 53% !important;
  }
}


@media (max-width:1045px) {
  div#new-card , div#new-card\ new-card-1 {
    height: 53% !important;
  }
}

@media (max-width:992px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card {
    height: 46% !important;
  }

  #ecommerce-card,
  div#new-card , 
  div#new-card\ new-card-1  {
    height: 53% !important;
  }
}

@media (max-width:991px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card {
    height: 39% !important;
  }

  #ecommerce-card,
  div#new-card ,
  div#new-card\ new-card-1 {
    height: 46% !important;
  }
}

@media (max-width:943px) {
  div#card\ seo_card {
    height: 47% !important;
  }
}

@media (max-width:910px) {

  div#card\ dev_card,
  div#card\ design-card {
    height: 46% !important;
  }

  div#card\ seo_card {
    height: 47% !important;
  }
}

@media (max-width:902px) {
  #ecommerce-card {
    height: 54% !important;
  }

  div#card\ seo_card {
    height: 47% !important;
  }
}


@media (max-width:768px) {

  div#card\ dev_card,
  div#card\ design-card,
  #ecommerce-card,
  div#new-card,
  div#card\ seo_card {
    height: 39% !important;
  }
}


@media (max-width: 723px) {

  #ecommerce-card,
  div#new-card\ new-card-1 {
    height: 47% !important;
  }
}


@media (max-width: 487px) {
  div#new-card {
    height: 47% !important;
  }
}

@media (max-width:445px) {

  div#card\ dev_card,
  div#card\ design-card,
  div#card\ seo_card {
    height: 47% !important;
  }
}

@media (max-width:437px) {
  #ecommerce-card {
    height: 54% !important;
  }
}


@media (max-width:348px) {
  div#new-card\ new-card-1 {
    height: 54% !important;
  }
}

@media (max-width:342px) {
  div#new-card\ new-card-1 {
    height: 62% !important;
  }
}

@media (max-width:337px) {
  div#new-card {
    height: 55% !important;
  }
}